import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';

import SEO from '../components/SEO';
import Hero from '../components/Hero/Hero';
import TextBlock from '../components/TextBlock';
import SimpleCard from '../components/SimpleCard/SimpleCard';
import CtaBlock from '../components/CtaBlock';
import CTAButton, { UiButton } from '../components/CTAButton/CTAButton';
import { withQuoteWizard } from '../components/withQuoteWizard';
import ServiceIntroBlock from '../components/ServiceIntroBlock';
import CenterContent from '../components/CenterContent';

const styles = theme =>
  createStyles({
    cards: {
      backgroundColor: '#FFFFFF',
    },
  });

class ThePage extends React.Component {
  render() {
    const { classes, pageContext: { projectData }, toggleQuoteComp } = this.props;
    const ctaType = get(projectData, 'bottomCta.type');
    const isQuoteCta = ctaType === 'quote';
    const isContactCta = ctaType === 'contact';
  
    return (
      <React.Fragment>
        <SEO
          title={`${projectData.title} - Sportsfield Construction project - Twin View Turf`}
          keywords={[
            `Sportsfield Construction project`,
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Hero heroImage={projectData.heroImg} noQuote>
          <TextBlock text={projectData.title} />
        </Hero>

        <Grid container item className={classes.cards}>
          <ServiceIntroBlock
            checklist={projectData.headline.bulletpoints}
            statementTitle={projectData.headline.title}
            statementCopy={projectData.headline.content}
          />
        </Grid>

        {projectData.content.map((item) => {
          let card = null;

            if (item.type === 'statement-card') {
            card = (
              <Grid key={item.content.title} container item className={classes.cards}>
                <CenterContent style={{ maxWidth: 960 }}>
                  <SimpleCard
                    image={item.content.image || `notProvided.jpg`}
                    title={item.content.title}
                    copy={item.content.copy}
                  />
                </CenterContent>
              </Grid>
            );
          }

            return card;
        })}

        { isQuoteCta && (
          <CtaBlock title={projectData.bottomCta.title}>
            <UiButton size="large" btnType="primary" onClick={toggleQuoteComp}>
              {projectData.bottomCta.btnTitle}
            </UiButton>
          </CtaBlock>
        )}

        { isContactCta && (
          <CtaBlock title={projectData.bottomCta.title}>
            <CTAButton
              inverted
              ctaHref="/contact"
              ctaText={projectData.bottomCta.btnTitle}
            />
          </CtaBlock>
        )}

        { (!isQuoteCta && !isContactCta) && (
          <CtaBlock title="Planning a field construction?">
            <UiButton size="large" btnType="primary" onClick={toggleQuoteComp}>
              Get quote
            </UiButton>
          </CtaBlock>
        )}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage)
);
