import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import Image from '../Image';

const styles = theme =>
  createStyles({
    root: {
      [theme.breakpoints.up('md')]: {
        padding: 40,
      }
    },
    imageWrap: {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        width: 350,
        height: 230,
      },
    },
    image: {
      width: '100vw',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        height: 230,
      },
    },
    contentWrap: {
      padding: 25,
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        padding: 0,
        paddingLeft: 40,
      }
    },
    title: {
      paddingBottom: 20,
    }
  });

const SimpleCard = ({
  title,
  copy,
  image: imageRelativePath,
  classes,
}) => {
  return (
    <Grid
      item
      container
      className={classes.root}
    >
      
      <Grid item container className={classes.imageWrap}
        xs={12}
        md={6}
      >
        <Image relativePath={imageRelativePath} className={classes.image} />
      </Grid>
      <Grid
        item
        container
        direction="column"
        className={classes.contentWrap}
        justify="center"
        xs={12}
        md={6}
      >
        <Grid item className={classes.titleWrap}>
          <Typography variant="body2" component="h4" className={classes.title}>
            {title}
          </Typography>
        </Grid>

        <Grid item className={classes.copyWrap}>
          <Typography
            variant="body1"
            className={classes.copy}
            dangerouslySetInnerHTML={{ __html: copy }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(SimpleCard);
